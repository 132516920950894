import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import { Images } from "../../../themes";
import { LoadWallet } from "../../../utils/api/actionConstants";

import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { useWallet, useUser } from "../../../hooks/useStore";
import { getData } from "../../../utils/api/apiHelper";

import { getAmPm, getHourMinute } from "../../../utils/Common";
import { AppRoute } from "../../../router/routerConstants";
import DateFilter from "../../../components/DateFilter";

const MyWallet = () => {
  const [NavigateTo] = useCustomNavigate();

  const dispatch = useDispatch();
  let wallet = useWallet();
  let user = useUser();

  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState();

  useEffect(() => {
    getWalletData(filters);
  }, []);

  const filterVisibility = (status) => {
    setShowFilter(status);
  };

  const renderTransactionImage = (param) => {
    switch (param.name) {
      case "PlatformCharge":
      case "CreatorSubscription":
      case "Transfer":
        return <img src={Images.debit} alt="debit" />;
      case "Withdrawal":
        return <img src={Images.debit} alt="debit" />;
      case "Recharge":
        return <img src={Images.ic_add_money_wallet} alt="" />;
      case "Received":
      case "CreatorSubscriptionReceived":
        return <img src={Images.credit} alt="" />;
      case "Refund":
        return <img src={Images.credit} alt="" />;
      case "Subscription":
        return <img src={Images.debit} alt="debit" />;
      default:
        return <></>;
    }
  };
  const renderTransactionTitle = (param) => {
    switch (param.transactionType.name) {
      case "CreatorSubscription":
      case "Transfer":
        return <h3>Paid to {param?.otherUser?.name}</h3>;
      case "PlatformCharge":
        return <h3>Platform Charges</h3>;
      case "Received":
      case "CreatorSubscriptionReceived":
        return <h3>Money received from {param?.otherUser?.name}</h3>;
      case "Recharge":
        return <h3>Money added from</h3>;
      case "Refund":
        return <h3>Refund from </h3>;
      case "Subscription":
        return <h3>Premium Plan Subscribe </h3>;
      case "Withdrawal":
        return <h3>Withdrawal is success </h3>;
      default:
        return <></>;
    }
  };

  const getWalletData = (fil) => {
    dispatch(
      getData({
        action: LoadWallet,
        data: {
          offset: 0,
          limit: 100,
          startDate: fil?.startDate ?? "",
          endDate: fil?.endDate ?? "",
        },
      })
    );
  };

  const onSubmitFilter = (filters) => {
    setFilters(filters);
    setShowFilter(false);

    getWalletData(filters);
  };

  let currentBalance = wallet.length > 0 ? wallet[0].current : 0;
  return (
    <div className="wrapper">
      <Header showLogout={true} />
      <div className="container">
        <div className="request-purchased-wrap">
          <div className="heading-block">
            <span className="back-btn" onClick={() => NavigateTo(-1)}>
              <img src={Images.backImg} alt="back-btn" />
            </span>
            <h1>My Wallet</h1>
          </div>
          <div className="earning-block">
            <div className="my-wallet earning-inner">
              <div className="left-block">
                <div className="wallet-icon">
                  <img src={Images.ic_wallet} />
                </div>
                <h5>Wallet Balance</h5>
                <h2>${currentBalance}</h2>
              </div>
              {user && user.roleType == "Creator" ? (
                <div
                  className="right-block"
                  onClick={() => {
                    if (wallet && wallet.length > 0) {
                      NavigateTo(AppRoute.Crea_Withdraw, false, wallet[0]);
                    }
                  }}
                >
                <div className="icon-box">
                  <figure>
                    <img src={Images.ic_withdraw} />
                  </figure>
                </div>
                </div>
              ) : (
                <div
                  className="right-block"
                  onClick={() => NavigateTo(AppRoute.Cons_AddMoney)}
                >
                  <figure>
                    <img src={Images.ic_add_money_wallet} />
                  </figure>
                </div>
              )}
            </div>
          </div>
          { user && user.roleType == "Creator" ? (
            <>
          <div
              className="my-account-detail-item my-wallet"
              onClick={() => NavigateTo(AppRoute.Withdrawal_request)}
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.ic_withdraw} alt="wallet" />
                </figure>
                <span className="item-name">Withdrawal Requests</span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div>

            <div
              className="my-account-detail-item manage-bank"
              onClick={() => NavigateTo(AppRoute.ManageBank)}
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.bank_icon} alt="wallet" />
                </figure>
                <span className="item-name">Manage Bank</span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div>

            <div
              className="my-account-detail-item manage-address"
              onClick={() => NavigateTo(AppRoute.ManageAddress)}
            >
              <div className="left-content">
                <figure className="img-icon">
                  <img src={Images.address_icon} alt="wallet" />
                </figure>
                <span className="item-name">Manage Address</span>
              </div>
              <span className="right-arrow">
                <img src={Images.chevron_right} alt="arrow" />
              </span>
            </div>
            </>
            ): null}
            
          <div className="filter-block">
            <div className="filter-inner">
              <h3>Wallet History</h3>
              <span className="fiter-icon">
                <img
                  src={Images.filter_icon}
                  alt="filter icon"
                  onClick={() => filterVisibility(true)}
                />
              </span>
            </div>
          </div>
          <div className="view-request-items wallet-items">
          {wallet && wallet.length > 0 ? (
              wallet.map((v, k) => {
                return (
                  <>
                    <div className="view-request-list" key={k}>
                      <div className="view-request-img">
                        <figure>
                          {renderTransactionImage(v.transactionType)}
                        </figure>
                      </div>
                      <div className="view-request-detail">
                        <div className="View-request-inner">
                          <div className="wallet-top-block">
                            {renderTransactionTitle(v)}
                            {v.type === "addition" ? (
                              <div className="price credit">+${v.amount}</div>
                            ) : (
                              <div className="price debit">-${v.amount}</div>
                            )}
                          </div>
                          <div className="wallet-bottom-block">
                            <p>
                              {getHourMinute(v.createdAt)}{" "}
                              {getAmPm(v.createdAt)}
                            </p>
                            <p>closing Balance : ${v.current}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
            )):(
              <div className="no-data-found">No data found</div>
            )}
          </div>
          {showFilter && (
            <DateFilter
              modalVisibility={filterVisibility}
              filters={filters}
              filtersSubmit={onSubmitFilter}
            />
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyWallet;
