import { useEffect, useState } from "react";
import { Images } from "../../../themes";
import { useCustomNavigate } from "../../../utils/hooks/useCustomNavigate";
import { AppRoute } from "../../../router/routerConstants";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import BlurryLoadingImage from "../../../components/BlurryLoadingImage";
import { useSelector, useDispatch } from "react-redux";
import { getData, saveData } from "../../../utils/api/apiHelper";
import { useLocation } from "react-router-dom";
import "../../../assets/css/profile-select.css";
import Lightbox from "../../../components/Lightbox";
import { toast } from "react-toastify";
import {
  Creator_Profile,
  CreateChat,
  SubscribeToCreator,
  UpdateCreatorProfileSubscribed,
} from "../../../utils/api/actionConstants";
import { IsSubscribed } from "../../../utils/Common";
import Subscription from "../../Subscription";
import FullScrenLoader from "../../../components/FullScreenLoader";
import {useParams} from 'react-router-dom';
import { getStorage, getStorageJson } from "../../../utils/localStorage";
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const CreatorProfile = () => {
  const [NavigateTo] = useCustomNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {id} = useParams();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
 
  const openLightbox = (index) => {
    //console.log(index)
    setCurrentIndex(index);
    setLightboxOpen(true);
  };
  const isUserLogin = () => {
    const token = getStorage("token");
    const user = getStorageJson("user");

    if (token && user && user.roleId) {
      return true;
    }
    return false;
  };
  const closeLightbox = () => {
    setLightboxOpen(false);
  };
  const profileData = useSelector((state) => {
    return state.creator.creatorProfile;
  });

  const { state } = useLocation();
  useEffect(() => {
    setLoading(true);
    dispatch(
      getData({
        action: Creator_Profile,
        data: { id: id },
      })
    );
  }, []);

  if (profileData && profileData.contentAmount && loading) {
    setLoading(false);
  }

  const startChat = (e) => {
    if (!isUserLogin()) {
      NavigateTo(AppRoute.CBENEFITS, false, { id: id });
    }else{
      dispatch(
        saveData(
          {
            action: CreateChat,
            data: {
              user: profileData.id,
            },
          },
          true
        )
      ).then((r) => {
        NavigateTo(AppRoute.ChatMessage, false, { chat: r });
      });
    }
  };

  // const subscribeToCreator = () => {
  //   dispatch(
  //     saveData(
  //       {
  //         action: SubscribeToCreator,
  //         data: {
  //           creatorId: profileData.id,
  //         },
  //       },
  //       true
  //     )
  //   ).then((r) => {
  //     if (r) {
  //       dispatch({ type: UpdateCreatorProfileSubscribed, payload: {} });
  //     }
  //   });
  // };
  let media=[]
  if (profileData?.allProfilePics){
     const mediaData= profileData.allProfilePics.map((v, k) => {
      if(!v.isBlurImages){
        return  { url: v.source, type:"photo" };
      } 
      
    })
    media = mediaData.filter(function( element ) {
      return element !== undefined;
  });
  }
  const showGallaryImage = (imgArray) => {
    let index = 0;
    return imgArray.map((data, key) => {
      if (!data.isBlurImages) {
        index++;
      }
      const newIndex = index-1;
      return (
        <SwiperSlide key={key}>
          <div className={data.isBlurImages ? 'inner-uploaded' : 'inner-uploaded pointer'} >
            {/* <figure onClick={() => (data.isBlurImages)?null:openLightbox(newIndex)}> */}
            <figure>
              <BlurryLoadingImage
                profilePic={data.source}
                isBlur={data.isBlurImages}
              />
            </figure>
           
          </div>
        </SwiperSlide>
      );
     
    }
   
  );
  };

  return (
    <div className="wrapper">
      <FullScrenLoader loading={loading} />
      <Header />
      <div className="container">
        <div className="profile-select-wrap">
          {/* <div className="top-profile-block">
            <figure>
              <BlurryLoadingImage
                profilePic={profileData.profilePic}
                isBlur={profileData.isBlur}
                fullImage={true}
              />
            </figure>
          </div> */}
          <div className="new-page-back">
            <span className="top-arrow">
              <img
                src={Images.tranparent_left_arrow}
                alt="arrow"
                onClick={() => NavigateTo(-1)}
              />
            </span>
          </div>
          <div className="profile-gallery-img">
              {(profileData.allProfilePics &&
                profileData.allProfilePics.length > 0 )?
                <>
                <Swiper
                  modules={[Navigation, Pagination]}
                  spaceBetween={10}
                  slidesPerView={3}
                  navigation
                  pagination={{ clickable: true }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                  }}
                >{showGallaryImage(profileData.allProfilePics)}</Swiper>
                </>:null

              } 
              {/* lightboxOpen && (
                <Lightbox media={media} startIndex={currentIndex} onClose={closeLightbox} />
              ) */}
          </div>
          <div className="profile-content">
            <h1>
              {profileData.name}{/*  , {profileData.age}  */}
            </h1>
            <h3 className="share-link">Share this profile: <a title="Copy profile share link" onClick={async()=>{
              await navigator.clipboard.writeText(window.location.href);
              toast.success('Copied');
            }} className="pointer"><svg width="20" height="20" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M66.6667 0C63.1304 0 59.7391 1.40476 57.2386 3.90524C54.7381 6.40573 53.3333 9.79711 53.3333 13.3333C53.3393 14.2706 53.444 15.2045 53.6458 16.1198L23.3724 31.2565C22.1238 29.819 20.5818 28.6656 18.8502 27.8739C17.1185 27.0822 15.2374 26.6706 13.3333 26.6667C9.79711 26.6667 6.40573 28.0714 3.90524 30.5719C1.40476 33.0724 0 36.4638 0 40C0 43.5362 1.40476 46.9276 3.90524 49.4281C6.40573 51.9286 9.79711 53.3333 13.3333 53.3333C15.238 53.3312 17.1201 52.921 18.8529 52.1304C20.5857 51.3398 22.129 50.1871 23.3789 48.75L53.6328 63.8802C53.4354 64.7959 53.335 65.7299 53.3333 66.6667C53.3333 70.2029 54.7381 73.5943 57.2386 76.0947C59.7391 78.5952 63.1304 80 66.6667 80C70.2029 80 73.5943 78.5952 76.0947 76.0947C78.5952 73.5943 80 70.2029 80 66.6667C80 63.1304 78.5952 59.7391 76.0947 57.2386C73.5943 54.7381 70.2029 53.3333 66.6667 53.3333C64.762 53.3355 62.8799 53.7457 61.1471 54.5363C59.4143 55.3269 57.871 56.4795 56.6211 57.9167L26.3672 42.7865C26.5646 41.8707 26.665 40.9368 26.6667 40C26.6603 39.065 26.5555 38.1332 26.3542 37.2201L56.6276 22.0833C57.8768 23.5196 59.4191 24.6718 61.1507 25.4624C62.8823 26.253 64.7631 26.6636 66.6667 26.6667C70.2029 26.6667 73.5943 25.2619 76.0947 22.7614C78.5952 20.2609 80 16.8696 80 13.3333C80 9.79711 78.5952 6.40573 76.0947 3.90524C73.5943 1.40476 70.2029 0 66.6667 0Z" fill="black"/>
            </svg></a></h3>

            <h3 className="share-link">Chat with her: <a title="Chat" onClick={startChat} className="pointer"> 
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M208 352c114.9 0 208-78.8 208-176S322.9 0 208 0S0 78.8 0 176c0 38.6 14.7 74.3 39.6 103.4c-3.5 9.4-8.7 17.7-14.2 24.7c-4.8 6.2-9.7 11-13.3 14.3c-1.8 1.6-3.3 2.9-4.3 3.7c-.5 .4-.9 .7-1.1 .8l-.2 .2s0 0 0 0s0 0 0 0C1 327.2-1.4 334.4 .8 340.9S9.1 352 16 352c21.8 0 43.8-5.6 62.1-12.5c9.2-3.5 17.8-7.4 25.2-11.4C134.1 343.3 169.8 352 208 352zM448 176c0 112.3-99.1 196.9-216.5 207C255.8 457.4 336.4 512 432 512c38.2 0 73.9-8.7 104.7-23.9c7.5 4 16 7.9 25.2 11.4c18.3 6.9 40.3 12.5 62.1 12.5c6.9 0 13.1-4.5 15.2-11.1c2.1-6.6-.2-13.8-5.8-17.9c0 0 0 0 0 0s0 0 0 0l-.2-.2c-.2-.2-.6-.4-1.1-.8c-1-.8-2.5-2-4.3-3.7c-3.6-3.3-8.5-8.1-13.3-14.3c-5.5-7-10.7-15.4-14.2-24.7c24.9-29 39.6-64.7 39.6-103.4c0-92.8-84.9-168.9-192.6-175.5c.4 5.1 .6 10.3 .6 15.5z"/></svg>
            </a></h3>

            <h4>{profileData.heading}</h4>
            <p>{profileData.aboutMe}</p>
            
          </div>
          
            <span className="chat-tab" onClick={startChat}>
              <img src={Images.ic_message} alt="message" />
            </span>
          {/* old code */}
          { /*
          {profileData.isBought ? (
            <>
              <span className="chat-tab" onClick={startChat}>
                <img src={Images.ic_message} alt="message" />
              </span>

              <div
                className="btn-block"
                onClick={() => {
                  NavigateTo(AppRoute.CONS_FOLDER);
                }}
              >
                <span className="submit">View Private folder</span>
              </div>
            </>
          ) : (
            <div>
              {profileData && profileData.contentAmount && (
                <Subscription profileData={profileData} />
              )}
            </div>
            // <div className="btn-block" onClick={subscribeToCreator}>
            //   <span className="submit">Subscribe To Creator</span>
            // </div>
          )} */ }
          {/* old code */}
          {/* {IsSubscribed() ? (
            <span className="chat-tab" onClick={startChat}>
              <img src={Images.ic_message} alt="message" />
            </span>
          ) : (
            <Subscription />
          )} */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CreatorProfile;
